<template>
    <header class="mdui-appbar mdui-appbar-fixed" style="background: #7B8ACA">
        <div class="mdui-toolbar">
            <span class="mdui-typo-title">宝藏插件库</span>
            <div class="mdui-toolbar-spacer"></div>
            <a href="javascript:location.reload();" class="mdui-btn mdui-btn-icon">
                <i class="mdui-icon material-icons">refresh</i>
            </a>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderCom"
}
</script>

<style scoped>

</style>
